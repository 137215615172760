import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import injectRuntimeOverride_qCF7mcRxCYT0da_rwtn9BwZK_HjzRWjmYCoePk2Vw_A from "/opt/buildhome/repo/plugins/injectRuntimeOverride.ts";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_6yjpF5YWnI0c3JEjQBpt6Zpb76oFn37ONKmsKyC83Ck from "/opt/buildhome/repo/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import strapi_sKhLINQNdwt_mhHLPJB0LMn9ASgy8vLNsKSmAAgEZsg from "/opt/buildhome/repo/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.js";
import adProfit_client__TA_m61zUkUpVok4yVth9KXoXxVqZcLWNiX9_IynRDo from "/opt/buildhome/repo/plugins/adProfit.client.ts";
import api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc from "/opt/buildhome/repo/plugins/api.ts";
import cookieConsent_aszqY_Bkp5EgG_zxAv4l7_xOM3MQo74MGdg8SpC9kl8 from "/opt/buildhome/repo/plugins/cookieConsent.ts";
import gpt_client__XV8XLQQtqzcUb4oPmzppeN_yffCYURi_uGAsBHbrzk from "/opt/buildhome/repo/plugins/gpt.client.ts";
import i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c from "/opt/buildhome/repo/plugins/i18n.ts";
import intlFormatter_rnME6o5R1elz_lKZ_QyTQ2oDzTyTtpceB_dkgNNgWg0 from "/opt/buildhome/repo/plugins/intlFormatter.js";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/opt/buildhome/repo/plugins/sentry.client.ts";
import striphtml_2pgdvoLMcMIcBSxm6wSr2c7lj5cFks6OXuhd76j_XmA from "/opt/buildhome/repo/plugins/striphtml.js";
import trackPurchaseComplete_client_9YK775SzB_EdumaPP7MS8_rmwKqJGcuXoChcfT9sJmU from "/opt/buildhome/repo/plugins/trackPurchaseComplete.client.ts";
import vue_gtm_llHxSOCLLYwn4OuhiFPxw9tbCjAQi7UrpjEQsdMsosU from "/opt/buildhome/repo/plugins/vue-gtm.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  injectRuntimeOverride_qCF7mcRxCYT0da_rwtn9BwZK_HjzRWjmYCoePk2Vw_A,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  fontawesome_6yjpF5YWnI0c3JEjQBpt6Zpb76oFn37ONKmsKyC83Ck,
  strapi_sKhLINQNdwt_mhHLPJB0LMn9ASgy8vLNsKSmAAgEZsg,
  adProfit_client__TA_m61zUkUpVok4yVth9KXoXxVqZcLWNiX9_IynRDo,
  api_jy9dy79pM_nYQuRKPrcfNg56p_gKkzw9SekUTZgAenc,
  cookieConsent_aszqY_Bkp5EgG_zxAv4l7_xOM3MQo74MGdg8SpC9kl8,
  gpt_client__XV8XLQQtqzcUb4oPmzppeN_yffCYURi_uGAsBHbrzk,
  i18n_M6WuPocwmDZfR2LKAqoIP7SPPiCebMfT5sB7ls3Be_c,
  intlFormatter_rnME6o5R1elz_lKZ_QyTQ2oDzTyTtpceB_dkgNNgWg0,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  striphtml_2pgdvoLMcMIcBSxm6wSr2c7lj5cFks6OXuhd76j_XmA,
  trackPurchaseComplete_client_9YK775SzB_EdumaPP7MS8_rmwKqJGcuXoChcfT9sJmU,
  vue_gtm_llHxSOCLLYwn4OuhiFPxw9tbCjAQi7UrpjEQsdMsosU
]