import type { JobAdListDto } from '~/typesAuto/jobshot/v1'
import type { EventPerson } from '~/typesManual/eventPerson'
import type { EcommerceItem } from '~/typesManual/dataLayer'

export default function () {
  const gtm = useGtm()
  const config = useRuntimeConfig()
  const route = useRoute()

  const userStore = useUserStore()
  const trackingStore = useTrackingStore()
  const indexStore = useIndexStore()

  // NEW TRACKING
  const getUserCoreTrackingData = () => {
    const trackingData: { [key: string]: unknown } = {}
    if (import.meta.server) {
      return trackingData
    }

    trackingData['isLoggedIn'] = userStore.isLoggedIn
    if (userStore.isLoggedIn && userStore.user) {
      trackingData['userGuid'] = userStore.user.userGuid

      if (userStore.user.clientId) {
        trackingData['clientId'] = userStore.user.clientId
      }
      if (userStore.user.email) {
        trackingData['email'] = userStore.user.email
      }

      trackingData['hasActiveSubscription'] =
        userStore.user?.hasActiveSubscription
    }

    return trackingData
  }

  const getRouteCoreTrackingData = () => {
    const trackingData: { [key: string]: unknown } = {}
    if (!route.name || import.meta.server) {
      return trackingData
    }

    trackingData['routeName'] = route.name?.toString()
    trackingData['paperId'] = indexStore.currentPaperIdentifier

    return trackingData
  }

  const addToTracking = (dataObject: { [key: string]: unknown }) => {
    trackingStore.dataBay = { ...trackingStore.dataBay, ...dataObject }
  }

  const resetDatabay = () => {
    trackingStore.dataBay = {}
  }

  const trackUserLogin = () => {
    const userCoreData = getUserCoreTrackingData()

    const payload: { [key: string]: unknown } = {
      event: 'userLogin',
      ...userCoreData,
    }
    gtm?.push(payload)
  }

  const trackUserLogout = () => {
    const userCoreData = getUserCoreTrackingData()

    const payload: { [key: string]: unknown } = {
      event: 'userLogout',
      ...userCoreData,
    }
    gtm?.push(payload)
  }

  const trackPageView = () => {
    const payload: { [key: string]: unknown } = {
      ...trackingStore.dataBay,
    }

    gtm?.push({
      event: 'pageView',
      ...payload,
    })
  }

  const trackSaveToReadingList = () => {
    gtm?.push({
      event: 'saveToReadingList',
    })
  }

  // END NEW TRACKING

  return {
    addToTracking,
    basic: {
      getUserCoreTrackingData,
      getRouteCoreTrackingData,
      trackPageView,
      trackUserLogin,
      trackUserLogout,
      resetDatabay,
      trackSaveToReadingList,
    },

    ecommerce: {
      addToCart: (item: EcommerceItem) => {
        const userCoreData = getUserCoreTrackingData()
        gtm?.push({
          event: 'add_to_cart',
          ecommerce: { currency: config.public.site.currency, items: [item] },
          ...userCoreData,
        })
      },
      removeFromCart: (item: EcommerceItem) => {
        const userCoreData = getUserCoreTrackingData()
        gtm?.push({
          event: 'remove_from_cart',
          ecommerce: { currency: config.public.site.currency, items: [item] },
          ...userCoreData,
        })
      },
      beginCheckout: (items: EcommerceItem[], value: number | undefined) => {
        const userCoreData = getUserCoreTrackingData()
        gtm?.push({
          event: 'begin_checkout',
          ecommerce: { currency: config.public.site.currency, items, value },
          ...userCoreData,
        })
      },
      purchase: (
        items: EcommerceItem[],
        paymentMethod: string,
        transactionId: string[],
        value: number,
        marketingPermission: boolean
      ) => {
        const userCoreData = getUserCoreTrackingData()
        gtm?.push({
          event: 'purchase',
          ecommerce: {
            currency: config.public.site.currency,
            items,
            paymentMethod,
            transactionId,
            value,
            marketingPermission,
          },
          ...userCoreData,
        })
      },
    },

    job: {
      // deprecated still in use should be moved to use the page view event.
      view: (id: number, jobTitle: string) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'jobView',
          id,
          title: jobTitle,
          ...userCoreData,
        })
      },

      applyClick: (id: number) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'jobApplyClick',
          jobAdId: id,
          ...userCoreData,
        })
      },
      listClick: (
        job: JobAdListDto,
        placement: string,
        highlightStatus: string
      ) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'jobListClick',
          jobAdId: job.id,
          placement,
          eventLabel: job.title,
          highlightStatus: highlightStatus,
          ...userCoreData,
        })
      },
      listImpression: (ids: number[], placement: string) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'jobListImpression',
          ids,
          placement,
          ...userCoreData,
        })
      },
    },

    podcast: {
      play: (articleId: string, title: string) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'podcast.play',
          articleId,
          title,
          ...userCoreData,
        })
      },
    },

    promotionClick: (
      id: string,
      name: string,
      creative: string,
      position: string
    ) => {
      const userCoreData = getUserCoreTrackingData()

      gtm?.push({
        event: 'promotionClick',
        ecommerce: {
          promoClick: {
            promotions: [
              {
                id,
                name,
                creative,
                position,
              },
            ],
          },
        },
        ...userCoreData,
      })
    },

    commercialSites: {
      contactMeOpen: (route: string) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'arena',
          action: 'contactMeOpen',
          route,
          ...userCoreData,
        })
      },
      contactMeSubmit: (route: string, lead: object) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'arena',
          action: 'submitContactMe',
          route,
          lead,
          ...userCoreData,
        })
      },
      joinEventOpen: (
        eventType: 'course' | 'network' | 'conference',
        route: string
      ) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'arena',
          action: `${eventType}.openJoinEvent`,
          route,
          ...userCoreData,
        })
      },
      joinEventNextPage: (eventType: string, route: string, step: number) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'arena',
          action: `${eventType}.nextPage`,
          route,
          step,
          ...userCoreData,
        })
      },
      joinEventSubmit: (
        eventType: 'course' | 'network' | 'conference',
        route: string,
        eventPeople?: EventPerson[]
      ) => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'arena',
          action: `${eventType}.submit`,
          route,
          participants: eventPeople,
          ...userCoreData,
        })
      },
    },

    trial: {
      startTrialClick: () => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'trial',
          action: 'startTrialClick',
          ...userCoreData,
        })
      },
      createUser: () => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'trial',
          action: 'createUser',
          ...userCoreData,
        })
      },
      completeClient: () => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'trial',
          action: 'completeClient',
          ...userCoreData,
        })
      },
      activateUser: () => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'trial',
          action: 'activateUser',
          ...userCoreData,
        })
      },
      trialOver: () => {
        const userCoreData = getUserCoreTrackingData()

        gtm?.push({
          event: 'trial',
          action: 'trialOver',
          ...userCoreData,
        })
      },
    },
    vaa: {
      complete: (electionId: number) => {
        gtm?.push({
          event: 'vaa.complete',
          electionId,
        })
      },
      skippedSignup: (electionId: number) => {
        gtm?.push({
          event: 'vaa.skippedSignup',
          electionId,
        })
      },
      electionPromoShown: (electionId: number) => {
        gtm?.push({
          event: 'vaa.electionPromoShown',
          electionId,
        })
      },
    },
  }
}
